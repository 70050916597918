import { inject, Injectable } from "@angular/core";
import { LitePropsStorageService } from "src/app/lite/local-db/resources/properties/lite-props/lite-props.storage.service";
import { StorageSyncService } from "src/app/lite/local-db/storage.sync.service";
import { TasksSortService } from "src/app/lite/ui/tasks/tasks-sort/tasks-sort.service";
import {
   NotificationService,
   NotificationTarget,
   NotificationType,
} from "src/app/shared/components/global/banners/notification-banner/notification-banner.service";

@Injectable({ providedIn: "root" })
export class LitePropsStorageSyncService extends StorageSyncService {
   private readonly litePropsStorageService = inject(LitePropsStorageService);
   private readonly tasksSortService = inject(TasksSortService);
   private readonly notificationService = inject(NotificationService);

   public constructor() {
      super();
   }

   public async syncTaskListSortBind(sortBind: string): Promise<void> {
      return this.sync(async () => {
         const sortDir = sortBind.startsWith("-") ? "desc" : "asc";
         const sortBy = this.tasksSortService.getValidSortBy(sortBind);
         await this.litePropsStorageService.setTaskListSortBind(sortBy);
         await this.litePropsStorageService.setTaskListSortDirection(sortDir);
      });
   }

   public async queryCurrentUserID(): Promise<number | null> {
      return this.query(() => this.litePropsStorageService.getCurrentUserID$());
   }

   public async queryIsFromLite(): Promise<boolean | null> {
      return this.query(() => this.litePropsStorageService.getIsFromLite$());
   }

   public async queryHasSyncError(): Promise<boolean | null> {
      return this.query(() => this.litePropsStorageService.getHasSyncError$());
   }

   public async syncCurrentUserID(currentUserID: number): Promise<void> {
      return this.sync(async () => {
         await this.litePropsStorageService.setCurrentUserID(currentUserID);
      });
   }

   public async syncHasSyncErrorReset(): Promise<void> {
      return this.sync(async () => {
         await this.litePropsStorageService.setHasSyncError(false);
      });
   }

   public async syncIsFromLiteReset(): Promise<void> {
      return this.sync(async () => {
         await this.litePropsStorageService.setIsFromLite(false);
      });
   }

   public async checkLiteProps() {
      const isFromLite = await this.queryIsFromLite();
      if (!isFromLite) return;

      // Add fragment to the URL so that Survicate knows we just came out of Lite.
      window.location.hash = "fromLite";

      const hasSyncError = await this.queryHasSyncError();
      if (hasSyncError) {
         this.notificationService.showNotification({
            message: "You're back online, but syncing errors occurred.",
            target: NotificationTarget.Online,
            notificationType: NotificationType.Error,
         });
         this.syncHasSyncErrorReset();
      } else {
         this.notificationService.showNotification({
            message: "Welcome back online!",
            target: NotificationTarget.Online,
            notificationType: NotificationType.Success,
         });
      }

      this.syncIsFromLiteReset();
   }

   public async syncLitePropsReset(): Promise<void> {
      return this.sync(async () => {
         await this.litePropsStorageService.resetLiteProps();
      });
   }
}
